import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  html, body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
    min-height: 100vh;
    min-width: 310px;
    padding: 0;
    color: black;
    scroll-behavior: smooth;
    width: 100%;
    ${"" /* */}
  }

  h1 {
    font-size: 1.8em;
    color: white;
    font-weight: 700
  }

  ul, ol{
    margin-top: 0;
  }

  h2 {
    font-size: 1.6em;
    font-weight: 600;
    padding-bottom: 25px;
  }
  
  *, *::before, *::after {
    box-sizing: border-box;
  }
    
  *:focus {
    outline: none;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  p{
    color: #0b1317;
  }
  h1, h2{
    font-family: 'Comfortaa', serif;
  }
    
  a {
    text-decoration: none;
    font-family: 'Comfortaa', serif;
  }

  li {
    padding-bottom: 20px;
    font-family: 'Comfortaa', sans-serif;
    color: #0b1317;
  }

  table{
    margin-bottom: 25px;
    border-collapse: collapse;
  }

  table, th, td {
  border: 1px solid lightgrey;
  padding: 10px;
}

  p, table {
    font-size: 1em;
    font-family: 'Comfortaa', sans-serif;
  }
  @media screen and (min-width: 576px) {
    body{
      font-size: 15px;
    }
  }
  @media screen and (min-width: 768px) {
    body{
      font-size: 16px;
    }
  }
  @media screen and (min-width: 992px) {
    body{
      font-size: 17px;
    }
  }
  @media screen and (min-width: 1200px) {
    body{
      font-size: 18px;
    }
  }



  ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #E3E3E3;
    }
    ::-webkit-scrollbar-thumb {
      background: #686868;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #333333;
    }
  }
`

export default GlobalStyle
