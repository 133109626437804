import * as React from "react"
import GlobalHead from "./GlobalHead"
import GlobalStyle from "./GlobalStyle"
import { Header } from "../modules/navigation"
import { Footer } from "@upshiftbe/react-components"
import { Link } from "gatsby"

const GlobalCollection = ({ children }) => {
  return (
    <React.Fragment>
      <GlobalHead />
      <GlobalStyle />
      <Header />
      {children}
      <Footer
        backgroundColor={"#00628f"}
        company={"Hilde Van Heuven"}
        color={"white"}
        vat={"0640.818.523"}
        phone={"0472 10 89 06"}
        address={"Rochuslei 41, 2930 Brasschaat"}
        email={"hilde.vanheuven@yahoo.com"}
        socialMediaTitle=""
      >
        <Link to="/">Welkom</Link>
        <Link to="/werkwijze">Werkwijze</Link>
        <Link to="/specialisaties">Specialisaties</Link>
        <Link to="/opleidingen">Opleidingen</Link>
        <Link to="/tarieven">Tarieven</Link>
        <Link to="/afspraak-maken">Afspraak maken</Link>
      </Footer>
    </React.Fragment>
  )
}

export default GlobalCollection
