import styled from "styled-components"

const CallToAction = styled.button`
  background-color: white;
  border-radius: 5px;
  border: none;
  color: black;
  font-family: "Comfortaa", sans-serif;
  cursor: pointer;
  font-size: 1em;
  margin: 5px 0;
  padding: 5px 10px;
  &.right {
    float: right;
  }
`

export default CallToAction
