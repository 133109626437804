import styled from "styled-components"

const Blob = styled.div`
  background-image: linear-gradient(to top left, #00628f, #0a9ccc 75%);
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 20%;
  height: 250px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  box-shadow: 1px 1px 18px 7px rgba(0, 0, 0, 0.14);

  @media screen and (min-width: 40em) {
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 10%;
    height: ${props => (props.cheight ? props.cheight : `400px`)};
  }
`

export default Blob
