import React from "react"
import { Helmet } from "react-helmet"
// import Favicon from "../assets/images/logo_icon_128.png"

const GlobalHead = () => (
  <Helmet>
    <meta charset="UTF-8" />
    <meta name="description" content="Hilde Van Heuven, psychotherapeut" />
    <meta name="subject" content="Hilde Van Heuven, psychotherapeut" />
    <title>Hilde Van Heuven - Psychotherapeut</title>
    <meta
      name="keywords"
      content="Hilde Van Heuven, psychotherapeut, psychotherapie, psycholoog, Brasschaat, coachen, begeleiden, psychologie, therapie, behandeling, oplossingsgericht, cognitief, systemisch, burn-out, burnout, burn out, hartcoherentie, Korzybski, gezinswetenschappen, hypnotherapie, cranio scraal, emdr, vac, verslaving, paniekaanval, stel, vind een therapeut"
    />
    <link
      rel="preload"
      href="https://fonts.googleapis.com/css2?&family=Comfortaa&family=Amatic+SC&family=Indie+Flower&family=Patrick+Hand&display=swap"
      as="style"
    ></link>
    <link
      href="https://fonts.googleapis.com/css2?&family=Comfortaa&family=Amatic+SC&family=Indie+Flower&family=Patrick+Hand&display=swap"
      rel="stylesheet"
    />
    <meta name="author" content="Hilde Van Heuven" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="robots" content="index, follow" />
    <meta name="googlebot" content="index,follow" />
    <meta property="og:url" content="https://hildevanheuven.be" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Hilde Van Heuven, psychotherapeut" />
    <meta
      property="og:description"
      content="Hilde Van Heuven, psychotherapeut"
    />
    <meta property="og:locale" content="nl_BE" />
    <meta property="article:author" content="Hilde Van Heuven" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:url" content="https://hildevanheuven.be" />
    <meta name="twitter:title" content="Hilde Van Heuven, psychotherapeut" />
    <meta
      name="twitter:description"
      content="Hilde Van Heuven, psychotherapeut"
    />
    <html lang="nl" />
  </Helmet>
)

export default GlobalHead
