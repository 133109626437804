import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import { Box } from "reflexbox"
import React, { useState } from "react"
import styled from "styled-components"
import CallToAction from "../../components/CallToAction"
import NavItem from "./NavItem"
import Menu from "./Menu"
import { faBars } from "@fortawesome/free-solid-svg-icons"

const Header = () => {
  const [open, setOpen] = useState(false)

  return (
    <NavFlex p={3}>
      <LogoLink to="/">
        <TextHelper>
          <StyledHilde>Hilde Van Heuven</StyledHilde>
          <StyledName>Psychotherapeut</StyledName>
        </TextHelper>
      </LogoLink>
      <MenuWrapper>
        <CallToAction onClick={() => setOpen(!open)}>
          <Stylediwhite icon={faBars} />
          Menu
        </CallToAction>
        <Menu open={open} setOpen={setOpen} />
      </MenuWrapper>
      <Links>
        <NavItem to="/" activeStyle={activeStyle}>
          Welkom
        </NavItem>
        <NavItem to="/werkwijze" activeStyle={activeStyle}>
          Werkwijze
        </NavItem>
        <NavItem to="/specialisaties" activeStyle={activeStyle}>
          Specialisaties
        </NavItem>
        <NavItem to="/opleidingen" activeStyle={activeStyle}>
          Opleidingen
        </NavItem>
        <NavItem to="/tarieven" activeStyle={activeStyle}>
          Tarieven
        </NavItem>
        <ActionLink
          className="cta"
          to="/afspraak-maken"
          activeStyle={activeStyleAlt}
        >
          Afspraak maken
        </ActionLink>
      </Links>
    </NavFlex>
  )
}

export default Header

const activeStyle = {
  borderBottom: "3px solid white",
}

const activeStyleAlt = {
  backgroundColor: "white",
  color: "black",
}

const ActionLink = styled(Link)`
  border: 3px solid white;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
  margin-left: 25px;
  font-family: "Comfortaa";
  font-weight: 700;
  display: inline-block;
  font-size: 1em;
  margin-bottom: 3px;
  transition: all 0.3s ease-in-out;
  position: relative;

  &:hover {
    background-color: white;
    color: black;
  }
`

const NavFlex = styled.nav`
  display: flex;
  padding: 1em;
  height: 100px;
  justify-content: space-between;
  margin: 0 auto !important;
  max-width: 1400px;
  align-items: center;
`

const Links = styled.div`
  align-self: center;

  @media (max-width: 911px) {
    display: none;
  }
`

const MenuWrapper = styled.div`
  color: black;
  flex: 1;
  text-align: right;
  align-self: center;
  font-size: 1.2em;
  margin-right: 10px;
  @media only screen and (min-width: 912px) {
    display: none;
  }
`

const Stylediwhite = styled(FontAwesomeIcon)`
  color: black;
  margin-right: 5px;
  height: 1em;
`

const LogoLink = styled(Link)`
  display: flex;
  align-self: center;
  align-items: center;
  min-height: 70px;
  font-family: "Indie Flower";
`

const TextHelper = styled(Box)`
  align-self: center;
  align-items: center;
  padding-left: 5px;
`

const StyledHilde = styled.p`
  color: white;
  font-family: "Comfortaa", serif;
  font-size: 1.4em;
  @media (max-width: 969px) {
    font-size: 1em;
  }
`

const StyledName = styled.p`
  color: white;
  font-family: "Comfortaa", serif;
  font-size: 1.4em;
  @media (max-width: 969px) {
    font-size: 1em;
  }
`
