import { Link } from "gatsby"
import styled from "styled-components"

const NavItem = styled(Link)`
  color: white;
  margin-left: 25px;
  padding-top: 3px;
  font-family: "Comfortaa";
  font-weight: 400;
  display: inline-block;
  font-size: 1em;
  margin-bottom: 3px;
  transition: all 0.3s ease-in-out;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    height: 3px;
    width: 0;
    background: transparent;
    transition: all 0.3s ease-in-out;
  }
  &:hover:after {
    width: 100%;
    left: 0;
    background: white;
  }
`

export default NavItem
