import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { bool } from "prop-types"

const Menu = ({ open, setOpen }) => (
  <StyledMenu open={open}>
    <RightTop onClick={() => setOpen(!open)}>x</RightTop>
    <Menulist>
      <MenuItem to="/" onClick={() => setOpen(!open)}>
        Welkom
      </MenuItem>
      <MenuItem to="/werkwijze" onClick={() => setOpen(!open)}>
        Werkwijze
      </MenuItem>
      <MenuItem to="/specialisaties" onClick={() => setOpen(!open)}>
        Specialisaties
      </MenuItem>
      <MenuItem to="/opleidingen" onClick={() => setOpen(!open)}>
        Opleidingen
      </MenuItem>
      <MenuItem to="/tarieven" onClick={() => setOpen(!open)}>
        Tarieven
      </MenuItem>
      <MenuItem to="/afspraak-maken" onClick={() => setOpen(!open)}>
        <CallToAction>Afspraak maken</CallToAction>
      </MenuItem>
    </Menulist>
  </StyledMenu>
)

export default Menu

const StyledMenu = styled.nav`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.95);
  z-index: 1;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateY(0)" : "translateY(100%)")};
`
const MenuItem = styled(Link)`
  margin: 15px 0;
  color: white;
  display: block;
  font-family: "Amatic SC";
  font-size: 1.8em;
  font-weight: 700;
`

const Menulist = styled.ul`
  list-style-type: none;
  padding: 0;
  text-align: center;
`

const RightTop = styled.div`
  text-align: right;
  margin-top: 10px;
  margin-right: 20px;
  font-family: "Comfortaa";
  cursor: pointer;
  font-size: 1.6em;
  font-weight: 700;
  color: white;
`

const CallToAction = styled.span`
  background-color: #00628f;
  color: white;
  border-radius: 5px;
  padding: 5px 15px;
  &.alt {
    background-color: white;
    color: black;
  }
`

Menu.propTypes = {
  open: bool.isRequired,
}
